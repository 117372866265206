<template>
  <div class="common_box">
    <router-link to="/payCostPage" class="pay-cost-page">
      <div class="pay-fixed-cont">
        <img src="../../assets/images/securityTrain/pc/pay-icon.png">
        <p>报名缴费</p>
      </div>
    </router-link>
    <a href="https://www.wjx.cn/vm/e2F49E3.aspx#"  class="h5-pay-cost-page">
      <!--    <router-link to="/h5PayCostPage" class="h5-pay-cost-page">-->
      <div class="pay-fixed-cont">
        <img src="../../assets/images/securityTrain/h5/home/erweima-icon.png">
        <p>点击报名</p>
      </div>
      <!--    </router-link>-->
    </a>
    <router-view/>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},
  methods: {},
  destroyed: function () {
    window.location.reload();
  },
  mounted: function () {
    var ymwl = {};
    ymwl.business_id = 2; // 2数据安全培训  3BBS商学院 4依智山东大区
    window.ymwl = ymwl;
    // 创建script标签，引入外部文件
    let scriptInfo = document.createElement('script')
    scriptInfo.type = 'text/javascript'
    scriptInfo.setAttribute("data-callType", "callScript")
    scriptInfo.src = 'http://cs.yizhivet.com/assets/layer/ymwl_common.js?v=1662001397'
    document.getElementsByTagName('head')[0].appendChild(scriptInfo)
  },
}
</script>
<style lang="less">
.pay-cost-page {
  .pay-fixed-cont {
    position: fixed;
    right: 5px;
    width: 70px;
    padding: 18px 0;
    background: #FF6D1E;
    top: 500px;
    z-index: 2000;

    p {
      font-size: 12px;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 30px;
    }

  }
}

.h5-pay-cost-page {
  .pay-fixed-cont {
    position: fixed;
    right: 5px;
    padding: 17px 15px;
    background: #FFFFFF;
    box-shadow: 0px 0px 51px 0px rgba(0, 0, 0, 0.42);
    border-radius: 50%;
    top: 70%;
    z-index: 2000;

    img {
      width: 22px;
      height: 22px;
    }

    p {
      font-size: 12px;
      font-weight: 500;
      color: #333333;
      line-height: 21px;
    }

  }
}
</style>
